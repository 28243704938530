import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor() {}

  public loadGTM(gtmId: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    const noScript = document.createElement('noscript');
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
              height="0" width="0" style="display:none;visibility:hidden;">
      </iframe>`;
    document.body.appendChild(noScript);
  }
}
