<header-navbar [activeComponent]="'aboutus'"></header-navbar>
<top-slider [activeSlide]="'informationsocietyservices'"></top-slider>

<div class="above-main-content">
    <div id="main-content" class="">
        <div class="shadow d-none d-lg-block"></div>
        <div class="content">
            <div class="row">
                <div class="col-md-12 pl-md-0 pr-md-0">
                    <h4 class="text-lg-left">Şirket Bilgileri</h4>
                    <div class="row">
                        <div class="col-md-10 col-12">                   
                                <div class="row mb-3 row-item">
                                    <div class="col-12 col-md-6 labelInfoSociety">Şirket Türü:</div>
                                    <div class="col-12 col-md-6 value">{{ company.CompanyType }}</div>
                                </div>
                          <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">MERSİS:</div>
                                <div class="col-12 col-md-6 value">{{ company.Mersis }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Ticaret Sicil Memurluğu:</div>
                                <div class="col-12 col-md-6 value">{{ company.TradeRegistryOffice }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Ticaret Sicil Numarası:</div>
                                <div class="col-12 col-md-6 value">{{ company.TradeRegistryNumber }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Ticaret Ünvanı:</div>
                                <div class="col-12 col-md-6 value">{{ company.TradeName }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Adres:</div>
                                <div class="col-12 col-md-6 value">{{ company.Address }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Taahüt Edilen Sermaye Miktarı:</div>
                                <div class="col-12 col-md-6 value">{{ company.CommittedCapitalAmount }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Ödenen Sermaye Miktarı:</div>
                                <div class="col-12 col-md-6 value">{{ company.PaidCapitalAmount }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Kayıtlı Sermaye Tavanı:</div>
                                <div class="col-12 col-md-6 value">{{ company.RegisteredCapitalCeiling }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Şirket Tescil Tarihi:</div>
                                <div class="col-12 col-md-6 value">{{ company.RegistrationDate }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Vergi Dairesi:</div>
                                <div class="col-12 col-md-6 value">{{ company.TaxOffice }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Vergi Numarası:</div>
                                <div class="col-12 col-md-6 value">{{ company.TaxNumber }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Sektör:</div>
                                <div class="col-12 col-md-6 value">{{ company.Sector }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">İletişim Bilgileri:</div>
                                <div class="col-12 col-md-6 value">{{ company.ContactInfo.split(' ')[0] }} {{ company.ContactInfo.split(' ')[1] }} 
                                    {{ company.ContactInfo.split(' ')[2] }} {{ company.ContactInfo.split(' ')[3] }}
                                     <br> {{ company.ContactInfo.split(' ')[4] }} </div>
                            </div>
                            <h4 class="text-lg-left">Yönetim Kurulu</h4>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Yönetim Kurulu Başkanı:</div>
                                <div class="col-12 col-md-6 value">{{ company.ChairmanofBoard }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Yönetim Kurulu Üyesi:</div>
                                <div class="col-12 col-md-6 value">{{ company.BoardMember1 }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Yönetim Kurulu Üyesi:</div>
                                <div class="col-12 col-md-6 value">{{ company.BoardMember2 }}</div>
                            </div>
                            <h4 class="text-lg-left">Denetçi</h4>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Dönem:</div>
                                <div class="col-12 col-md-6 value">{{ company.Period }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">İsim Soyisim / Ticaret Ünvanı:</div>
                                <div class="col-12 col-md-6 value">{{ company.AuditorName }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <div class="col-12 col-md-6 labelInfoSociety">Adres:</div>
                                <div class="col-12 col-md-6 value">{{ company.AuditorAddress }}</div>
                            </div>
                            <div class="row mb-3 row-item">
                                <a class="col-12 col-md-6 activity-report-year" href="#" data-toggle="modal" data-target="#activityReport-modal">Faaliyet Raporları</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</div>
<div class="modal fade" id="activityReport-modal" tabindex="-1" role="dialog" aria-labelledby="Е7С" aria-hidden="true">
    <div class="modal-dialog activityReport" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="./assets/img/cross.png" alt="Close" i18n-title title="Close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div 
                class="download-row download-row d-block text-center justify-content-center justify-content-md-between align-items-center">
                    <a href="/assets/documents/2020BagimsizDenetimRaporu.pdf" target="_blank"
                        class="activity-report-year" tabindex="0">2020</a>
                </div>
                <div 
                class="download-row download-row d-block text-center justify-content-center justify-content-md-between align-items-center">
                    <a href="/assets/documents/2021BagimsizDenetimRaporu.pdf" target="_blank"
                        class="activity-report-year" tabindex="0">2021</a>
                </div>
                <div 
                class="download-row download-row d-block text-center justify-content-center justify-content-md-between align-items-center">
                    <a href="/assets/documents/2022BagimsizDenetimRaporu.pdf" target="_blank"
                        class="activity-report-year" tabindex="0">2022</a>
                </div>
                <div 
                class="download-row download-row d-block text-center justify-content-center justify-content-md-between align-items-center">
                    <a href="/assets/documents/2023BagimsizDenetimRaporu.pdf" target="_blank"
                        class="activity-report-year" tabindex="0">2023</a>
                </div>
            </div>
        </div>
    </div>
</div>
<footer-template></footer-template>
