import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderNavbarModule } from './_shared-modules/header-navbar/header-navbar.module';
import { FooterTemplateModule } from './_shared-modules/footer-template/footer-template.module';
import { TopSliderModule } from './_shared-modules/top-slider/top-slider.module';
import { GoogleMapsModule } from './_shared-modules/google-maps/google-maps.module';
import { HttpClientModule } from '@angular/common/http';
import { MobileAppComponent } from './mobile-app/mobileapp.component';
import { ServicesComponent } from './services/services.component';
import { AboutUsComponent } from './about-us/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { DocumentsComponent } from './documents/documents.component';
import { PartnersComponent } from './partners/partners.component';
import { PayPointsComponent } from './pay-points/paypoints.component';
import { PayPointsAllComponent } from './pay-pointsall/paypointsall.component';
import { ServiceBase, ApiRequest, ApiResponse } from './base';
import { HttpModule } from '@angular/http';
import { SiteVariablesModel } from './_models/SiteVariablesModel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageModel } from './_models/MessageModel';
import * as bootstrap from "bootstrap";
import * as $ from "jquery";
import { MoneyTransferComponent } from './money-transfer/moneytransfer.component';
import { DomesticMoneyTransferModel } from './_models/DomesticMoneyTransferModel';
import { UppercaseModule } from './_shared-modules/uppercase/uppercase.module';
import { MapSearchDetailModule } from './_shared-modules/mapsearch-detail/mapsearch-detail.module';
import { MoneyTransferYourDetailsModule } from './_shared-modules/moneytransfer-yourdetails/moneytransfer-yourdetails.module';
import { MoneyTransferReceiverDetailsModule } from './_shared-modules/moneytransfer-receiverdetails/moneytransfer-receiverdetails.module';
import { MoneyTransferCreditDetailsModule } from './_shared-modules/moneytransfer-cardetails/moneytransfer-creditcarddetails.module';
import { PosModel } from './_models/PosModel';
import { PaymentComponent } from './Payment/payment.component';
import { PaymentConfirmComponent } from './PaymentConfirm/payment-confirm.component';
import { MessageBoxModule } from './_shared-modules/message-box/message-box.module';
import { PaymentFailComponent } from './PaymentFail/payment-fail.component';
import { NgxMaskModule } from 'ngx-mask';
import { VerifyComponent } from './verify/verify.component';
import { VerifyMailComponent } from './verify-mail/verifymail.component';
import { AppLinkComponent } from './appLink/applink.component';
import { BusinessMemberComponent } from './business-user/businessuser.component';
import { BusinessUserUserInfoModule } from './_shared-modules/businessuser-userinfo/businessuser-userinfo.module';
import { BusinessUserCompanyInfoModule } from './_shared-modules/businessuser-companyinfo/businessuser-companyinfo.module';
import { BusinessUserApplyModel } from './_models/BusinessUserApplyModel';
import { BusinessUserSendVerificationCodeModel } from './_models/BusinessUserSendVerificationCodeModel';
import { BusinessUserVerifyGsmModel } from './_models/BusinessUserVerifyGsmModel';
import { BusinessUserVerifyTcknModel } from './_models/BusinessUserVerifyTcknModel';
import { BusinessUserCollectionComponent } from './business-user-collection/collection.component';
import { PaymentConfirmBusinessComponent } from './PaymentConfirmBusiness/payment-confirm-business.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { InformationSocietyServicesComponent } from './information-society-services/informationsocietyservices.component';
import { GoogleTagManagerService } from './services/google-tag-manager/google-tag-manager.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    MobileAppComponent,
    ServicesComponent,
    AboutUsComponent,
    ContactComponent,
    DocumentsComponent,
    PartnersComponent,
    PayPointsComponent,
    PayPointsAllComponent,
    MoneyTransferComponent,
    PaymentComponent,
    PaymentConfirmComponent,
    PaymentFailComponent,
    VerifyComponent,
    VerifyMailComponent,
    AppLinkComponent,
    BusinessMemberComponent,
    BusinessUserCollectionComponent,
    PaymentConfirmBusinessComponent,
    InformationSocietyServicesComponent
    
  ],
  imports: [
    BrowserModule,
    HttpModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HeaderNavbarModule,
    FooterTemplateModule,
    TopSliderModule,
    GoogleMapsModule,
    HttpClientModule,
    UppercaseModule,
    MapSearchDetailModule,
    MoneyTransferYourDetailsModule,
    MoneyTransferReceiverDetailsModule,
    MoneyTransferCreditDetailsModule,
    MessageBoxModule,
    BusinessUserUserInfoModule,
    BusinessUserCompanyInfoModule,
    NgxMaskModule.forRoot(),
    RecaptchaModule.forRoot()

  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    ServiceBase,
    ApiRequest,
    ApiResponse,
    SiteVariablesModel,
    MessageModel,
    DomesticMoneyTransferModel,
    PosModel,
    BusinessUserApplyModel,
    BusinessUserSendVerificationCodeModel,
    BusinessUserVerifyGsmModel,
    BusinessUserVerifyTcknModel,
    GoogleTagManagerService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Lct6ikqAAAAAAjfFZ365XYKhiRW59pmM80eqWDw', hl: 'tr' } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

