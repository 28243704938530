import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiRequest, ServiceBase } from 'src/app/base';
import * as Script from '../_shared-modules/scripts/script';
import { ActivatedRoute } from '@angular/router';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';

@Component({
    templateUrl: './partners.component.html',
})
export class PartnersComponent implements OnInit {

    partnerRequestForm: FormGroup;
    captcha: string;
    isCaptchaShow: boolean = true;


    constructor(private fb: FormBuilder, public serviceBase: ServiceBase, private route: ActivatedRoute, public siteVariablesModel: SiteVariablesModel, public messageModel: MessageModel) {
        this.partnerRequestForm = this.fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])],
            'name': [null, Validators.compose([Validators.required])],
            'company': [null, Validators.compose([Validators.required])],
            'gsmnumber': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
            'message': [null, Validators.compose([Validators.maxLength(4000)])]
        });
        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {
        Script.validatePartnerRequestFields();

        this.route.queryParams.subscribe(params => {

            let sc = params['sc'];
            if (sc == "partner-request") {
                setTimeout(() => {
                    Script.scrollTOElement('#partner-request', -50, 0);
                }, 50);
            }
        });

    }

    async addPartnerRequest(formData, valid: boolean) {
        if (this.isCaptchaShow) {
            if (!this.captcha) {
                if (this.siteVariablesModel.language === "TR") {
                    this.messageModel.showError("", "Lütfen ben robot değilim seçeneğini işaretleyiniz!");
                } else {
                    this.messageModel.showError("", "Please check the 'I am not a robot' option!");
                }
                return;
            }
    
            const captchaRequest = new ApiRequest();
            captchaRequest.AddDataObjectToRequest("MethodPath", "CaptchaController/CaptchaControl");
            captchaRequest.AddDataObjectToRequest("DataObjects", {});
            captchaRequest.AddDataObjectToRequest("Captcha", this.captcha);
    
            try {
                await this.serviceBase.Execute(captchaRequest);
                if (!this.serviceBase.apiResponse.IsSuccessful) {
                    this.messageModel.showError("", this.serviceBase.apiResponse.ErrorMessage);
                    return;
                }
            } catch (error) {
                this.messageModel.showError("", "Captcha validation failed. Please try again.");
                return;
            }
        }
    
        if (valid) {
            const request = new ApiRequest();
            request.AddDataObjectToRequest("MethodPath", "PartnerRequestController/InsertPartnerRequest");
            request.AddDataObjectToRequest("Email", formData.email);
            request.AddDataObjectToRequest("Name", formData.name);
            request.AddDataObjectToRequest("Company", formData.company);
            request.AddDataObjectToRequest("GSMNumber", formData.gsmnumber);
            request.AddDataObjectToRequest("Message", formData.message);
    
            try {
                await this.serviceBase.Execute(request);
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    this.partnerRequestForm.reset({
                        'email': '',
                        'name': '',
                        'company': '',
                        'gsmnumber': '',
                        'message': ''
                    });
                    if (this.siteVariablesModel.language === "TR") {
                        this.messageModel.showSuccess("Teşekkürler", "Sizinle en kısa sürede iletişime geçeceğiz");
                    } else {
                        this.messageModel.showSuccess("Thank you", "We will contact with you as soon as possible");
                    }
                } else {
                    if (this.siteVariablesModel.language === "TR") {
                        this.messageModel.showError("Bir problem var", "Bilgileriniz kaydedilirken bir hata oluştu, lütfen tekrar deneyiniz");
                    } else {
                        this.messageModel.showError("Ooops there's a problem", "An error occurred when saving your information, please try again");
                    }
                }
            } catch (error) {
                this.messageModel.showError("", "An unexpected error occurred. Please try again.");
            }
        }
    }
    
    resolved(captchaResponse: string) {
      this.captcha = captchaResponse;
    }

}
