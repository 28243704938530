var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiRequest, ServiceBase } from 'src/app/base';
import * as Script from '../_shared-modules/scripts/script';
import { ActivatedRoute } from '@angular/router';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';
var ContactComponent = /** @class */ (function () {
    function ContactComponent(fb, serviceBase, route, siteVariablesModel, messageModel) {
        this.fb = fb;
        this.serviceBase = serviceBase;
        this.route = route;
        this.siteVariablesModel = siteVariablesModel;
        this.messageModel = messageModel;
        this.isCaptchaShow = true;
        this.askQuestionForm = this.fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])],
            'name': [null, Validators.compose([Validators.required])],
            'message': [null, Validators.compose([Validators.maxLength(4000)])],
            'subject': [null, Validators.compose([Validators.required])],
            'gsmnumber': [null, Validators.compose([Validators.required, Validators.minLength(10)])],
        });
        this.siteVariablesModel.setLanguage();
    }
    ContactComponent.prototype.ngOnInit = function () {
        Script.validateAskAQuestionFields();
        this.route.queryParams.subscribe(function (params) {
            var sc = params['sc'];
            if (sc == "ask-a-question") {
                setTimeout(function () {
                    Script.scrollTOElement('#ask-a-question', -50, 0);
                }, 50);
            }
        });
    };
    ContactComponent.prototype.addQuestion = function (formData, valid) {
        return __awaiter(this, void 0, void 0, function () {
            var captchaRequest, error_1, questionRequest, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isCaptchaShow) return [3 /*break*/, 4];
                        if (!this.captcha) {
                            if (this.siteVariablesModel.language === "TR") {
                                this.messageModel.showError("", "Lütfen ben robot değilim seçeneğini işaretleyiniz!");
                            }
                            else {
                                this.messageModel.showError("", "Please check the 'I am not a robot' option!");
                            }
                            return [2 /*return*/];
                        }
                        captchaRequest = new ApiRequest();
                        captchaRequest.AddDataObjectToRequest("MethodPath", "CaptchaController/CaptchaControl");
                        captchaRequest.AddDataObjectToRequest("DataObjects", {});
                        captchaRequest.AddDataObjectToRequest("Captcha", this.captcha);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.serviceBase.Execute(captchaRequest)];
                    case 2:
                        _a.sent();
                        if (!this.serviceBase.apiResponse.IsSuccessful) {
                            this.messageModel.showError("", this.serviceBase.apiResponse.ErrorMessage);
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.messageModel.showError("", "Captcha validation failed. Please try again.");
                        return [2 /*return*/];
                    case 4:
                        if (!valid) return [3 /*break*/, 8];
                        Script.addProcessing($('#ask-a-question .submit').parents('form'));
                        questionRequest = new ApiRequest();
                        questionRequest.AddDataObjectToRequest("MethodPath", "QuestionController/InsertQuestion");
                        questionRequest.AddDataObjectToRequest("Email", formData.email);
                        questionRequest.AddDataObjectToRequest("Name", formData.name);
                        questionRequest.AddDataObjectToRequest("Message", formData.message);
                        questionRequest.AddDataObjectToRequest("Subject", formData.subject);
                        questionRequest.AddDataObjectToRequest("GSMNumber", formData.gsmnumber);
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.serviceBase.Execute(questionRequest)];
                    case 6:
                        _a.sent();
                        Script.removeProcessing($('#ask-a-question .submit').parents('form'));
                        if (this.serviceBase.apiResponse.IsSuccessful) {
                            this.askQuestionForm.reset({
                                email: '',
                                name: '',
                                message: '',
                                subject: '',
                                gsmnumber: ''
                            });
                            if (this.siteVariablesModel.language === "TR") {
                                this.messageModel.showSuccess("Teşekkürler", "Sorunuz en kısa sürede yanıtlanacaktır");
                            }
                            else {
                                this.messageModel.showSuccess("Thank you", "Your question will be answered as soon as possible");
                            }
                        }
                        else {
                            if (this.siteVariablesModel.language === "TR") {
                                this.messageModel.showError("Bir problem var", "Sorunuz kaydedilirken bir hata oluştu, lütfen tekrar deneyiniz");
                            }
                            else {
                                this.messageModel.showError("Ooops there's a problem", "An error occurred when saving your question, please try again");
                            }
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        error_2 = _a.sent();
                        Script.removeProcessing($('#ask-a-question .submit').parents('form'));
                        this.messageModel.showError("", "An unexpected error occurred. Please try again.");
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ContactComponent.prototype.resolved = function (captchaResponse) {
        this.captcha = captchaResponse;
    };
    return ContactComponent;
}());
export { ContactComponent };
