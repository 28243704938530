<header-navbar [activeComponent]="'documents'"></header-navbar>
<top-slider [activeSlide]="'documents'"></top-slider>

<div class="above-main-content">
    <div id="main-content" class="documents-page">
        <div class="shadow d-none d-lg-block"></div>
        <div class="content">
            <div class="row">
                <div class="col-md-12 pl-md-0 pr-md-0">
                    <h4 i18n="@@document-library" class="text-center text-lg-left">Document Library</h4>
                    <!-- <div class="year text-center text-lg-left">2018</div> -->
                    <div class="download-list">
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document1-desc" class="name">
                                Contract for One-Time Transaction
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/Contract.pdf" target="_blank"
                                    class="buttonDefault button-red size26" tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document2-desc" class="name">
                                Terms and Conditions
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/GonderAlWebSiteTermsofUse.pdf" target="_blank"
                                    class="buttonDefault button-red size26" tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document4-desc" class="name">
                                Cookie Policy
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/CookiePolicy.pdf" target="_blank"
                                    class="buttonDefault button-red size26" tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document5-desc" class="name">
                                Data Owner Application Form
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/DataOwnerApplicationForm.pdf" target="_blank"
                                    class="buttonDefault button-red size26" tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document6-desc" class="name">
                                Personal Data Protection Privacy Notice
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/PersonalDataProtectionPrivacyNotice.pdf"
                                    target="_blank" class="buttonDefault button-red size26" tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document7-desc" class="name">
                                MoneyGram's Compliance Program
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="http://corporate.moneygram.com/compliance/" target="_blank"
                                    class="buttonDefault button-red size26" tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->

                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document8-desc" class="name">
                                Application To TODEB Customer Complaints Arbitration Panel Information Text
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/HakemHeyetiBilgilendirme.pdf" target="_blank" class="buttonDefault button-red size26"
                                    tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document9-desc" class="name">
                                Regulation on the Principles and Procedures of The Association of Payment and Electronic Money Institutions of Turkey Complaints Arbitration Panel
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/HakemHeyetiYonetmelik.pdf" target="_blank" class="buttonDefault button-red size26"
                                    tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document10-desc" class="name">
                                Customer Complaints Arbitration Panel and Application Process Information Brochure
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="/assets/documents/HakemHeyetiBrosur.pdf" target="_blank" class="buttonDefault button-red size26"
                                    tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->
                        <!-- row start -->
                        <div
                            class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                            <div i18n="@@document11-desc" class="name">
                                Customer Complaints Arbitration Panel Application Form
                                <!-- <span>(Transition Date: 10.11.2018)</span> -->
                            </div>
                            <div class="button">
                                <a i18n="@@open" href="https://todeb.org.tr/hakemheyetibasvuruformu/" target="_blank" class="buttonDefault button-red size26"
                                    tabindex="0">Open</a>
                            </div>
                        </div>
                        <!-- end row start -->

                         <!-- row start -->
                         <div
                         class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                         <div i18n="@@document12-desc" class="name">
                            Terms, Risks and Exceptions Regarding Our Services
                             <!-- <span>(Transition Date: 10.11.2018)</span> -->
                         </div>
                         <div class="button">
                             <a i18n="@@open" href="/assets/documents/SunulanHizmetlereIliskinSartlarRisklerVeIstisnaiDurumlar.pdf" target="_blank" class="buttonDefault button-red size26"
                                 tabindex="0">Open</a>
                         </div>
                     </div>
                     <div
                     class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                     <div i18n="@@document13-desc" class="name">
                      Identification If Beneficial Owner
                     </div>
                     <div class="button">
                         <a i18n="@@open" href="/assets/documents/SucGelirlerininAklanmasininOnlenmesiHakkindaBilgilendirme.pdf" target="_blank" class="buttonDefault button-red size26"
                             tabindex="0">Open</a>
                     </div>
                 </div>
                     <div
                         class="download-row d-block text-center text-md-left d-md-flex justify-content-center justify-content-md-between align-items-center">
                         <div i18n="@@document14-desc" class="name">
                            Consumer Rights Information Form
                         </div>
                         <div class="button">
                             <a i18n="@@open" href="/assets/documents/Tüketici_Hakları_Bilgilendirme_Formu.pdf" target="_blank" class="buttonDefault button-red size26"
                                 tabindex="0">Open</a>
                         </div>
                     </div>
                     <!-- end row start -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<footer-template></footer-template>
