import * as i0 from "@angular/core";
var GoogleTagManagerService = /** @class */ (function () {
    function GoogleTagManagerService() {
    }
    GoogleTagManagerService.prototype.loadGTM = function (gtmId) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        document.head.appendChild(script);
        var noScript = document.createElement('noscript');
        noScript.innerHTML = "\n      <iframe src=\"https://www.googletagmanager.com/ns.html?id=" + gtmId + "\" \n              height=\"0\" width=\"0\" style=\"display:none;visibility:hidden;\">\n      </iframe>";
        document.body.appendChild(noScript);
    };
    GoogleTagManagerService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleTagManagerService_Factory() { return new GoogleTagManagerService(); }, token: GoogleTagManagerService, providedIn: "root" });
    return GoogleTagManagerService;
}());
export { GoogleTagManagerService };
