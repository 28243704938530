import { Component } from '@angular/core';
import { GoogleTagManagerService } from './services/google-tag-manager/google-tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private gtmService: GoogleTagManagerService) {}
  ngOnInit(): void {
    this.gtmService.loadGTM('GTM-KN45FMC');
  }
  title = 'GonderalWebsite';
}
