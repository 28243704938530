import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiRequest, ServiceBase } from 'src/app/base';
import * as Script from '../_shared-modules/scripts/script';
import { ActivatedRoute } from '@angular/router';
import { SiteVariablesModel } from '../_models/SiteVariablesModel';
import { MessageModel } from '../_models/MessageModel';

@Component({
    templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {

    askQuestionForm: FormGroup;
    captcha: string;
    isCaptchaShow = true;

    constructor(private fb: FormBuilder, public serviceBase: ServiceBase, private route: ActivatedRoute, public siteVariablesModel: SiteVariablesModel, public messageModel: MessageModel) {
        this.askQuestionForm = this.fb.group({
            'email': [null, Validators.compose([Validators.required, Validators.email])],
            'name': [null, Validators.compose([Validators.required])],
            'message': [null, Validators.compose([Validators.maxLength(4000)])],
            'subject': [null, Validators.compose([Validators.required])],
            'gsmnumber': [null, Validators.compose([Validators.required, Validators.minLength(10)])],

        });
        this.siteVariablesModel.setLanguage();
    }

    ngOnInit() {

        Script.validateAskAQuestionFields();

        this.route.queryParams.subscribe(params => {

            let sc = params['sc'];
            if (sc == "ask-a-question") {
                setTimeout(() => {
                    Script.scrollTOElement('#ask-a-question', -50, 0);
                }, 50);
            }
        });
    }

    async addQuestion(formData, valid: boolean) {
        if (this.isCaptchaShow) {
            if (!this.captcha) {
                if (this.siteVariablesModel.language === "TR") {
                    this.messageModel.showError("", "Lütfen ben robot değilim seçeneğini işaretleyiniz!");
                } else {
                    this.messageModel.showError("", "Please check the 'I am not a robot' option!");
                }
                return;
            } 
    
            const captchaRequest = new ApiRequest();
            captchaRequest.AddDataObjectToRequest("MethodPath", "CaptchaController/CaptchaControl");
            captchaRequest.AddDataObjectToRequest("DataObjects", {});
            captchaRequest.AddDataObjectToRequest("Captcha", this.captcha);
    
            try {
                await this.serviceBase.Execute(captchaRequest);
                if (!this.serviceBase.apiResponse.IsSuccessful) {
                    this.messageModel.showError("", this.serviceBase.apiResponse.ErrorMessage);
                    return;
                }
            } catch (error) {
                this.messageModel.showError("", "Captcha validation failed. Please try again.");
                return;
            }
        }
    
        if (valid) {
            Script.addProcessing($('#ask-a-question .submit').parents('form'));
            const questionRequest = new ApiRequest();
            questionRequest.AddDataObjectToRequest("MethodPath", "QuestionController/InsertQuestion");
            questionRequest.AddDataObjectToRequest("Email", formData.email);
            questionRequest.AddDataObjectToRequest("Name", formData.name);
            questionRequest.AddDataObjectToRequest("Message", formData.message);
            questionRequest.AddDataObjectToRequest("Subject", formData.subject);
            questionRequest.AddDataObjectToRequest("GSMNumber", formData.gsmnumber);
    
            try {
                await this.serviceBase.Execute(questionRequest);
                Script.removeProcessing($('#ask-a-question .submit').parents('form'));
    
                if (this.serviceBase.apiResponse.IsSuccessful) {
                    this.askQuestionForm.reset({
                        email: '',
                        name: '',
                        message: '',
                        subject: '',
                        gsmnumber: ''
                    });
                    if (this.siteVariablesModel.language === "TR") {
                        this.messageModel.showSuccess("Teşekkürler", "Sorunuz en kısa sürede yanıtlanacaktır");
                    } else {
                        this.messageModel.showSuccess("Thank you", "Your question will be answered as soon as possible");
                    }
                } else {
                    if (this.siteVariablesModel.language === "TR") {
                        this.messageModel.showError("Bir problem var", "Sorunuz kaydedilirken bir hata oluştu, lütfen tekrar deneyiniz");
                    } else {
                        this.messageModel.showError("Ooops there's a problem", "An error occurred when saving your question, please try again");
                    }
                }
            } catch (error) {
                Script.removeProcessing($('#ask-a-question .submit').parents('form'));
                this.messageModel.showError("", "An unexpected error occurred. Please try again.");
            }
        }
    }
    
    resolved(captchaResponse: string) {
      this.captcha = captchaResponse;
    }
}
